import styled, {createGlobalStyle} from "styled-components";
import BackGround from '../assets/images/BackGround.svg';
import {StyledInputProps} from "../store/types";


export const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    background: url(${BackGround}) no-repeat center center;

    //background-size: 100% auto; /* Растягивает по ширине */
    //background-position: top center; /* Можно изменить для регулировки положения */

`;

export const TopBlock = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: rgb(5, 5, 5);
    @media (max-width: 768px) {
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        opacity: 0.85;
    }
`;
export const LeftTopBlock = styled.div`
    display: flex;
    width: 400px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-left: 100px;
    @media (max-width: 768px) {
        padding-left: 10px;
    }
`;
export const RightTopBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding-right: 100px;
    z-index: 2;
    @media (max-width: 768px) {
        padding-right: 0;
        gap: 10px;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }
`;
export const Address = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: rgb(242, 218, 194);
    font-size: 14px;
    font-weight: 700;
    line-height: 200%;
    text-align: left;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;
`;
export const Phone = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: rgb(242, 218, 194);
    font-size: 14px;
    font-weight: 700;
    line-height: 200%;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;
`;
export const TextOpen = styled.div`
    color: rgb(151, 118, 86);
    font-size: 14px;
    font-weight: 700;
    line-height: 200%;
    text-align: left;
    text-transform: uppercase;
`;
export const AWEEK = styled.ul`
    width: 100%;
    color: rgb(242, 218, 194);
    font-size: 14px;
    font-weight: 700;
    line-height: 200%;
    text-align: center;
    text-transform: uppercase;
;`


export const Icon = styled.div<{ $image?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 25px;
    cursor: pointer;
    background: url(${({$image}) => $image}) no-repeat center center;
    transition: background-position 0.5s ease, transform 0.5s ease;
}
`;
export const BurgerIconWrapper = styled.div<{ $image?: string }>`
    position: absolute;
    top: -70px;
    right: 10px;
    width: 30px;
    height: 40px;
    cursor: pointer;
    background: url(${({$image}) => $image}) no-repeat center center;
    transition: background-position 0.5s ease, transform 0.5s ease;
}
`;
export const LogoImage = styled.div<{ $image?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 120px;
    cursor: pointer;
    background: url(${({$image}) => $image}) no-repeat center center;
    @media (max-width: 1024px) {
        display: none;
    }
`;
export const MenuBlock = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        max-width: 100%;
        height: auto;
        justify-content: flex-end;
        padding-right: 20px;
    }
`;
export const BurgerMenuIcon = styled.div`
    display: none;
    cursor: pointer;
    z-index: 1;
    @media (max-width: 768px) {
        position: relative;
        display: block;
    }
`;
export const Menu = styled.div<{ $isOpen?: boolean }>`
    position: relative;
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: 2;
    @media (max-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.8);
        display: ${({$isOpen}) => ($isOpen ? 'flex' : 'none')};
    }
`;
export const MenuItem = styled.div`
    position: relative;
    max-width: 100px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    color: rgb(242, 218, 194);
    font-size: 14px;
    font-weight: 700;
    line-height: 200%;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        color: rgb(151, 118, 86);
    }

    &:active {
        scale: 0.98;
    }

    @media (max-width: 768px) {
        margin: 15px 0;
        width: 100%;
        text-align: center;
    };`

export const CardBlock = styled.div`
    position: relative;
    width: 107px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    @media (max-width: 768px) {
        display: none;
    }
`;
export const CardText = styled.div`
    color: rgb(242, 218, 194);
    font-size: 12px;
    font-weight: 700;
    line-height: 200%;
    text-align: left;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;
`;
export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Abel';
        src: url('/fonts/Abel.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
    }

    body {
        margin: 0;
        padding: 0;
        background-color: #0e0b06;
        font-family: 'Abel', sans-serif;
        overflow: hidden; /* Prevent vertical scrolling */

    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    *:hover::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

export const NotificationWrapper = styled.div`
    position: fixed;
    top: 20px;
    right: 50px;
    width: 200px;
    height: 48px;
    padding: 20px;
    background-color: rgb(26, 29, 33);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 5;
    @media (max-width: 768px) {
        right: 0;
    }
`;

export const NotificationContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    z-index: 5;

`;

export const IconWrapper = styled.div`
    margin-right: 10px;
`;
export const AlertMessage = styled.div`
    font-size: 14px;
    position: relative;
    width: 150px;
    color: red;
    background: white;
`;

export const DangerTitle = styled.div`
    font-weight: bold;
    color: rgb(253, 86, 2);
    z-index: 5;

`;

export const DangerText = styled.div`
    color: white;
`;

export const CloseIconWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
`;
export const BlurRight = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 1140px;
    height: 1023px;
    filter: blur(200px);
    background: rgb(5, 5, 5);
    opacity: 0.4;
    @media (max-width: 768px) {
        display: none;
    }
`;
export const BlurLeft = styled.div`
    position: fixed;
    left: 0;
    top: 100px;
    width: 1140px;
    height: 1023px;
    filter: blur(200px);
    background: rgb(5, 5, 5);
    opacity: 0.6;
    z-index: 0;
    @media (max-width: 768px) {
        display: none;
    }
`;
export const InfoBlock = styled.div`
    position: relative;
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    text-align: start;
    padding-left: 100px;
    word-wrap: break-word; /* Перенос длинных слов */
    white-space: normal; /* Позволяет перенос строк */
    padding-top: 100px;
    @media (max-width: 768px) {
        padding-top: 0;
        width: 90%;
        max-width: 100%;
        padding-left: 10px;
    }
`;

export const Title = styled.div`
    color: rgb(242, 218, 194);
    font-size: 80px;
    font-weight: 700;
    line-height: 81px;
    text-align: start;
    text-transform: uppercase;
    @media (max-width: 768px) {
        font-size: 34px;
    }
`;

export const Info = styled.div`
    color: rgb(151, 118, 86);
    font-size: 48px;
    font-weight: 400;
    line-height: 49px;
    text-align: start;
    text-transform: uppercase;
    @media (max-width: 768px) {
        font-size: 34px;
    }
`
export const SmallText = styled.div`
    color: rgb(242, 218, 194);
    font-size: 16px;
    font-weight: 400;
    line-height: 200%;
    text-align: start;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;
export const Buttons = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    top: 20px;
    gap: 24px;
`;
export const Button = styled.button`
    position: relative;
    width: 154px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(108, 84, 61);
    color: white;
    background: rgb(151, 118, 86);
    font-size: 12px;
    font-weight: 700;
    line-height: 200%;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background: rgb(108, 84, 61);
        color: rgb(242, 218, 194);
        border: 1px solid rgb(151, 118, 86);

    }

    &:active {
        scale: 0.98;
    }
    @media (max-width: 768px) {
        width: 100px;
        height: 30px;
    }
`;
export const DeleteButton = styled.button`
    position: relative;
    width: 70px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(151, 118, 86);
    color: rgb(151, 118, 86);
    background: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 200%;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    margin: 5px;

    &:hover {
        background: rgb(151, 118, 86);
        color: rgb(242, 218, 194);
    }
    &:active {
        scale: 0.98;
    }
`;
export const SocialButtons = styled.div`
    position: relative;
    top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
`;
export const SocialIcon = styled.div<{ $image?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url(${({$image}) => $image}) no-repeat center center;
    transition: background-position 0.5s ease, transform 0.5s ease;
    &:active {
        scale: 0.98;
    }
}
`;
export const CardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 90%;
    gap: 20px;
    border-radius: 16px;
    background: rgba(26, 29, 33, 0.96);
    padding: 35px;
    z-index: 12;
    opacity: 0.97;
    @media (max-width: 768px) {
        max-width: 80%;
        max-height: 85%;
    }
`;
export const CardCenter = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-items: center;
    text-align: center;
    //gap: 20px;

    @media (max-width: 768px) {
        gap: 5px;
    }
`;
export const Input = styled.input<StyledInputProps>`
    position: relative;
    padding-left: 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 48px;
    border-radius: 8px;
    background: rgb(26, 29, 33);
    color: rgb(155, 156, 158);
    transition: border-color 0.3s ease;
    outline: none;
    box-sizing: border-box;
    resize: none;
    border: ${(props) => {
        if (props.$error) {
            return '1px solid rgb(208, 48, 47)';
        }
        if (!props.$warning && props.$hasText) {
            return '1px solid rgb(151, 118, 86)';
        } else {
            return '1px solid rgb(54, 58, 61)';
        }
    }
    };
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-webkit-calendar-picker-indicator {
        display: none;
    }

    &:not(:placeholder-shown) {
        border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
    }

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color 0.3s ease;
        box-sizing: border-box;
    }

    &:focus {
        border: 1px solid rgb(151, 118, 86);
        box-shadow: 0 0 0 1px rgb(117, 91, 65), 0 0 5px 4px rgba(132, 220, 245, 0.24);
        display: block;
    }

    &:focus::before {
        border-color: rgba(132, 220, 245, 0.24);
    }
`;
export const UL = styled.ul`
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
`;
export const LI = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    background: rgb(26, 29, 33);
    color: rgb(155, 156, 158);
    transition: border-color 0.3s ease;
    outline: none;
    box-sizing: border-box;
    resize: none;
    border: 1px solid rgb(151, 118, 86);
    box-shadow: 0 0 0 1px rgb(117, 91, 65), 0 0 1px 2px rgba(132, 220, 245, 0.24);
    list-style: none;
`;
export const LiWeek = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: rgb(242, 218, 194);
    outline: none;
    box-sizing: border-box;
    resize: none;
    list-style: none;
`;
export const Scroll = styled.div`
         position: relative;
         height: 100%;
         width: 100%;
         overflow-y: auto;
         overflow-x: hidden;
    `;
