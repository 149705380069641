import styled from "styled-components";

export const Buttons = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
    gap: 5px;
`;
export const Button = styled.button`
    position: relative;
    width: 70px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(151, 118, 86);
    color: rgb(151, 118, 86);
    background: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 200%;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background: rgb(151, 118, 86);
        color: rgb(242, 218, 194);
    }
    &:active {
        scale: 0.98;
    }
`;
