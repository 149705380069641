import React from 'react';
import './App.css';
import {AppRouter} from "./AppRouter";
import {BrowserRouter} from "react-router-dom";
import {GlobalStyle} from "./style/GlobalStyle";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
      <BrowserRouter>
          <GlobalStyle/>
          <AppRouter/>
      </BrowserRouter>
  );
}

export default App;
