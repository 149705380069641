import React, {useEffect, useState} from "react";
import {CrossIconWrapper} from "./styles";
import {
    Button,
    CardCenter,
    CardContentContainer,
    DeleteButton,
    Input,
    LI,
    SmallText,
    UL
} from "../../style/GlobalStyle";
import CircleIcon from "../../assets/CircleIcon";
import DatePicker from "react-datepicker";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {logoutFunc, onSignInSubmitFunc, onVerifyCodeSubmitFunc, setUpRecaptchaFunc} from "../../store/slices/userSlice";
import {Booking, INewUser} from "../../store/types";
import {db} from "../../firebaseConfig";
import {collection, query, where, getDocs, addDoc, deleteDoc, doc} from 'firebase/firestore';
import {useTranslation} from "react-i18next";
import {ScrollBookings} from "../Gallery/styles";


interface DialogContentSettingsTaskProps {
    setShowBlock?: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    user: null | INewUser
}

const CardContent: React.FC<DialogContentSettingsTaskProps> = ({
                                                                   setShowBlock, user
                                                               }: DialogContentSettingsTaskProps) => {
    const sending = useAppSelector(state => state.app.sending);
    const [phone, setPhone] = useState<string>(user?.phoneNumber || "+972");
    const [name, setName] = useState<string>(user?.displayName || "");

    const dispatch = useAppDispatch();
    const [date, setDate] = useState<Date | null>(null);
    const [previousBookings, setPreviousBookings] = useState<Booking[]>([]);
    const [verificationCode, setVerificationCode] = useState("");
    const [bookedDates, setBookedDates] = useState<Date[]>([]); // Store booked dates as Date objects
    const {t} = useTranslation();
    const [bookingToDelete, setBookingToDelete] = useState<string | null>(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    const confirmDelete = (id: string) => {
        setBookingToDelete(id);
        setShowConfirmDialog(true);
    };
    const handleConfirmDelete = async () => {
        if (bookingToDelete) {
            await handleDelete(bookingToDelete, setPreviousBookings, setBookedDates);
            setBookingToDelete(null);
            setShowConfirmDialog(false);
        }
    };
    const handleCancelDelete = () => {
        setBookingToDelete(null);
        setShowConfirmDialog(false);
    };
    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const bookingsCollection = collection(db, 'bookings');
                const q = query(bookingsCollection, where('date', '>=', new Date().toISOString()));
                const querySnapshot = await getDocs(q);

                const bookings: Booking[] = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        date: new Date(data.date), // Convert Timestamp to Date
                        phoneNumber: data.phoneNumber as string,
                        name: user?.displayName || "anonymous",
                    };
                })
                const filteredBookings = phone !== ('+972509859325' || '+9726414595')
                    ? bookings.filter(booking => booking.phoneNumber === phone)
                    : bookings;
                setPreviousBookings(filteredBookings);
                setBookedDates(bookings.map(booking => booking.date));
            } catch (error) {
                console.error('Error fetching bookings:', error);
            }
        };
        fetchBookings();
    }, [user?.displayName]);


    const handleSave = async () => {
        if (date) {
            try {
                const isoDate = date.toISOString();
                await addDoc(collection(db, 'bookings'), {
                    date: isoDate,
                    phoneNumber: phone,
                    name: name,
                });

                const bookingsCollection = collection(db, 'bookings');
                const q = query(bookingsCollection, where('date', '>=', new Date().toISOString()));
                const querySnapshot = await getDocs(q);

                const bookings: Booking[] = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        date: new Date(data.date), // Convert Timestamp to Date
                        phoneNumber: data.phoneNumber as string,
                        name: data?.name || "anonymous",
                    };
                })
                    .filter(booking => booking.phoneNumber === phone);

                setDate(null);
                setPreviousBookings(bookings);
                setBookedDates(bookings.map(booking => booking.date));
            } catch (error) {
                console.error('Error saving booking:', error);
            }
        }
    };
    const handleDelete = async (id: string, setPreviousBookings: (bookings: Booking[]) => void, setBookedDates: (dates: Date[]) => void) => {
        try {
            await deleteDoc(doc(db, 'bookings', id));

            const bookingsCollection = collection(db, 'bookings');
            const q = query(bookingsCollection, where('date', '>=', new Date().toISOString()));
            const querySnapshot = await getDocs(q);

            const bookings: Booking[] = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    date: new Date(data.date), // Convert Timestamp to Date
                    phoneNumber: data.phoneNumber as string,
                    name: data?.name || "anonymous",
                };
            });

            setPreviousBookings(bookings);
            setBookedDates(bookings.map(booking => booking.date));
        } catch (error) {
            console.error('Error deleting booking:', error);
        }
    };
    const isDateBooked = (date: Date) => {
        return bookedDates.some(bookedDate => {
            return bookedDate.getTime() === date.getTime();
        });
    };
    const getAvailableTimeIntervals = (date: Date) => {
        const availableTimes: Date[] = [];
        for (let hour = 9; hour < 20; hour++) {
            for (let minute = 0; minute < 60; minute += 60) {
                const time = new Date(date);
                time.setHours(hour, minute, 0, 0);
                if (!isDateBooked(time)) {
                    availableTimes.push(time);
                }
            }
        }
        return availableTimes;
    };
    const filterDate = (date: Date) => {
        return !isDateBooked(date);
    };
    const filterTime = (date: Date) => {
        const availableTimes = getAvailableTimeIntervals(date);
        return availableTimes.some(time => time.getTime() === date.getTime());
    };
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        // Allow backspacing/deletion to remove all numbers after +972
        if (newValue.length >= 4) {
            setPhone(newValue);
        }
    };
    const isValidPhoneNumber = (phone: string): boolean => {
        const phoneRegex = /^\+972\d{9}$/;
        return phoneRegex.test(phone);
    };
    return (
        <CardContentContainer>
            <CrossIconWrapper>
                <CircleIcon onClick={() => setShowBlock && setShowBlock(false)}/>
            </CrossIconWrapper>

            {user ? <CardCenter>
                    {showConfirmDialog && (
                        <>
                            <p>Are you sure you want to delete this booking?</p>
                            <DeleteButton onClick={handleConfirmDelete}>Yes</DeleteButton>
                            <DeleteButton onClick={handleCancelDelete}>No</DeleteButton>
                        </>
                    )}
                    {/*<Input placeholder={"Name"} value={phone} onChange={(e) => setPhone(e.target.value)}/>*/}
                    {/*<Input placeholder={"Phone number"} value={phone} onChange={(e) => setPhone(e.target.value)}/>*/}
                    {/*Time:*/}
                    {/*<DatePicker*/}
                    {/*    selected={date}*/}
                    {/*    onChange={(date) => {*/}
                    {/*        if (date !== null) {*/}
                    {/*            setDate(date);*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    showTimeSelect*/}
                    {/*    timeFormat="HH:mm"*/}
                    {/*    timeIntervals={15}*/}
                    {/*    dateFormat="MMMM d, yyyy h:mm aa"*/}
                    {/*    timeCaption="Time"*/}
                    {/*    customInput={<Input readOnly/>}*/}
                    {/*/>*/}
                    {/*<Button>Save</Button>*/}

                    <CardCenter>
                        {!showConfirmDialog &&
                            <>
                                <Input placeholder={"Name"} value={name} onChange={(e) => setName(e.target.value)}
                                       $hasText={name}/>
                                <Input placeholder={"Phone number"} value={phone} onChange={(e) => setPhone(e.target.value)}
                                       $hasText={phone}/>
                                {t("TimeLabel")}:
                                <DatePicker
                                    selected={date}
                                    onChange={setDate}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    useWeekdaysShort
                                    timeIntervals={60} // 60-minute intervals
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    timeCaption="Time"
                                    customInput={<Input readOnly $hasText={date}/>}
                                    filterDate={filterDate}
                                    filterTime={filterTime}
                                />

                                <Button onClick={handleSave}>
                                    {t("SaveButton")}
                                </Button>

                                <SmallText>{t("Previous")}</SmallText>
                            </>
                        }
                        <ScrollBookings>
                            <UL>
                                {previousBookings.slice(phone === ('+972509859325' || '+9726414595') ? -10 : -3).map((booking) => {
                                    const formattedDate = booking.date.toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric'
                                    });
                                    const formattedTime = booking.date.toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });

                                    return (
                                        <LI key={booking.id}>
                                            {`${formattedDate} ${formattedTime} - ${booking.phoneNumber}`}
                                            <DeleteButton onClick={() => confirmDelete(booking.id)}>Delete</DeleteButton>
                                        </LI>
                                    );
                                })}

                            </UL>
                        </ScrollBookings>
                        <Button onClick={() => dispatch(logoutFunc())}>{t("LogoutButton")}</Button>
                    </CardCenter>
                </CardCenter> :
                <CardCenter>
                    {!sending && <>
                        <div id="recaptcha-container"></div>
                        <Input
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder="Enter phone number"
                            $hasText={phone}
                        />
                        <Button onClick={() => {
                            if (isValidPhoneNumber(phone)) {
                                console.log('Valid phone number:', phone);
                            } else {
                                console.log('Invalid phone number. Please enter a valid number.');
                                return;
                            }
                            dispatch(setUpRecaptchaFunc());
                            dispatch(onSignInSubmitFunc(phone));
                        }}>{t("SendCodeButton")}
                        </Button>
                    </>}
                    {sending &&
                        <>
                            <Input
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                placeholder="Enter verification code"
                                $hasText={verificationCode}
                            />
                            <Button
                                onClick={() => dispatch(onVerifyCodeSubmitFunc(verificationCode))}>{t("VerificationButton")}:</Button> </>}
                </CardCenter>
            }
        </CardContentContainer>
    );
};
export default CardContent;
