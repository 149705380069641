import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const BlurNfc = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 1140px;
    height: 1023px;
    filter: blur(200px);
    background: rgb(5, 5, 5);
    opacity: 0.6;
    z-index: 0;
`;
export const TopText = styled.div`
    padding-top: 50px;
    color: rgb(242, 218, 194);
    font-size: 40px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    z-index: 1;
`;
export const BigText = styled.div`
    color: rgb(242, 218, 194);
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
    z-index: 1;
`;
export const SmallText = styled.div`
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
`;
export const Line = styled.div`
    position: absolute;
    width: 100%;
    height: 4px;
`;
export const LogoImage = styled.div<{ $image?: string }>`
    padding-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 125px;
    cursor: pointer;
    background: url(${({$image}) => $image}) no-repeat center center;
    z-index: 1;
`;
export const Item = styled.a<{$image?: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95px;
    height: 99px;
    border-radius: 10px;
    background: rgba(151, 118, 86, 0.3);
    color: white;
    border: 1px solid rgb(191, 150, 111);
    cursor: pointer;
    z-index: 1;
    font-size: 16px;
    font-weight: 700;
    line-height: 200%;
    text-transform: uppercase;

    &:hover {
        background: rgba(200, 199, 199, 0.3);
    }

    &:active {
        scale: 0.98;
    }

    &:before {
        content: "";
        position: relative;
        width: 95px;
        height: 99px;
        background: url(${({$image}) => $image}) no-repeat center center;
    }
`;
export const Column = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px;
`;
