import React from 'react';

import Telegram from "../../assets/images/telegram.svg";
import Instagram from "../../assets/images/instagram.svg";
import Map from "../../assets/images/maps.svg";
import Website from "../../assets/images/website.svg";
import Mail from "../../assets/images/mail.svg";
import Facebook from "../../assets/images/facobookicon.svg";
import Phone from "../../assets/images/phone.svg";
import Contact from "../../assets/images/contact.svg";
import WhatsApp from "../../assets/images/whatsapp.svg";
import {BigText, BlurNfc, Column, Container, Item} from "./styles";
import {CenterElements} from "../Gallery/styles";

const ContactComponent: React.FC = () => {
    const handleDownloadContact = () => {
        const link = document.createElement('a');
        link.href = './contact.vcf'; // Замените на путь к вашему VCF файлу
        link.download = 'contact.vcf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Container>
                <BigText>Expert Haircuts and Grooming Services</BigText>
                <BlurNfc/>
                <Column>
                    <Item $image={Telegram} href="https://t.me/davidzaguri">Telegram</Item>
                    <Item $image={Instagram} href="https://www.instagram.com/davidzaguri">Instagram</Item>
                    <Item $image={Map} href="https://ul.waze.com/ul?ll=31.88690475%2C34.78590131&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">Map</Item>
                    <Item $image={Website} href="https://davidzaguri-a2474.web.app/">Website</Item>
                    <Item $image={Mail} href="mailto:YK9wJ@example.com">Mail</Item>
                    <Item $image={Facebook} href="https://www.facebook.com">Facebook</Item>
                </Column>
                <BigText>Contact</BigText>
                <Column>
                    <Item $image={Phone} href="tel:+972526414595">Phone</Item>
                    <Item $image={Contact} onClick={handleDownloadContact}>Contact</Item>
                    <Item $image={WhatsApp} href="https://wa.me/972526414595">WhatsApp</Item>
                </Column>
        </Container>
    );
};

export default ContactComponent;
