import React from 'react';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import {CenterElements, Container, Scroll} from "./styles";

const Gallery: React.FC = () => {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    return (
        <Container>
            <CenterElements>
                <Scroll>
                    <LightGallery
                        onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                    >
                        <a
                            data-lg-size="1406-1390"
                            className="gallery-item"
                            data-src="https://plus.unsplash.com/premium_photo-1658507036857-a35a49fc95cc?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@entrycube' > Photo master </a></h4>"
                        >
                            <img
                                className="img-responsive"
                                src="https://plus.unsplash.com/premium_photo-1658507036857-a35a49fc95cc?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
                            />
                        </a>
                        <a
                            data-lg-size="1406-1390"
                            className="gallery-item"
                            data-src="https://plus.unsplash.com/premium_photo-1658506711778-d56cdeae1b9b?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                            data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@entrycube' > Photo master </a></h4>"
                        >
                            <img
                                className="img-responsive"
                                src="https://plus.unsplash.com/premium_photo-1658506711778-d56cdeae1b9b?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
                            />
                        </a>
                        <a
                            data-lg-size="1406-1390"
                            className="gallery-item"
                            data-src="https://plus.unsplash.com/premium_photo-1658506695819-15989df6d489?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                            data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@entrycube' > Photo master </a></h4>"
                        >
                            <img
                                className="img-responsive"
                                src="https://plus.unsplash.com/premium_photo-1658506695819-15989df6d489?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
                            />
                        </a>
                        <a
                            data-lg-size="1406-1390"
                            className="gallery-item"
                            data-src="https://images.unsplash.com/photo-1593702275687-f8b402bf1fb5?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                            data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@entrycube' > Photo master </a></h4>"
                        >
                            <img
                                className="img-responsive"
                                src="https://images.unsplash.com/photo-1593702275687-f8b402bf1fb5?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
                            />
                        </a>
                    </LightGallery>
                </Scroll>

            </CenterElements>
        </Container>
    );
};

export default Gallery;
