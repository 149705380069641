import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // Detects user language
    .use(initReactI18next) // Passes i18n instance to react-i18next
    .init({
        fallbackLng: 'en', // Default language
        resources: {
            en: {
                translation: require('./public/locales/en/translation.json')
            },
            ru: {
                translation: require('./public/locales/ru/translation.json')
            },
            he: {
                translation: require('./public/locales/he/translation.json')
            }
        }
    });

export default i18n;
