import React, {useState} from "react";
import {
    Address,
    AWEEK, BlurLeft, BlurRight, BurgerIconWrapper, BurgerMenuIcon,
    Container, Icon,
    LeftTopBlock, LI, LiWeek, LogoImage, Menu, MenuBlock, MenuItem,
    Phone,
    RightTopBlock,
    TopBlock
} from "../../style/GlobalStyle";
import LocationIcon from '../../assets/images/LocationIcon.svg';
import PhoneIcon from '../../assets/images/PhoneIcon.svg';
import BurgerIcon from '../../assets/images/BurgerIcon.svg';
import LogoImageIcon from '../../assets/images/logo-no-background.svg';
import {useAppSelector} from "../../store/hooks";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../../style/LanguageSwitcher";
import {Outlet, useNavigate} from "react-router-dom";


const MainPage: React.FC = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const error = useAppSelector(state => state.app.error);
    const [showNotification, setShowNotification] = React.useState(true);

    const closeNotificationHandler = () => {
        setShowNotification(false);
    };
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };
    return (
        <Container>

            <TopBlock>
                {/*<DangerComponent show={showNotification} close={closeNotificationHandler} error={error}*/}
                {/*                 color="green"*/}
                {/*                 icon={<span>⚠️</span>}*/}
                {/*                 type={DangerMessageType.Error}/>*/}
                <LeftTopBlock>
                    {/*<TextOpen>*/}
                    {/*    {t('textOpen')}*/}
                    {/*</TextOpen>*/}
                    <AWEEK>
                        <LiWeek>{t('sun-thu')}</LiWeek>
                        <LiWeek>{t('10-20')}</LiWeek>
                        <LiWeek>{t('friday')}</LiWeek>
                        <LiWeek>{t('sut')}</LiWeek>
                    </AWEEK>
                    <LanguageSwitcher/>
                </LeftTopBlock>
                <RightTopBlock>
                    <Address
                        as="a"
                        href="https://ul.waze.com/ul?ll=31.88690475%2C34.78590131&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                        <Icon $image={LocationIcon}/>
                        קלמן גבריאלוב 18 רחובות
                    </Address>
                    <Phone as="a" href="tel:0526414595">
                        <Icon $image={PhoneIcon}/>
                        052-641-45-95
                    </Phone>
                </RightTopBlock>
            </TopBlock>
            <MenuBlock>
                <BurgerMenuIcon onClick={toggleMenu}>
                    <BurgerIconWrapper $image={BurgerIcon}/>
                </BurgerMenuIcon>
                <BlurLeft/>
                <LogoImage style={{paddingLeft: '100px'}} $image={LogoImageIcon}/>
                <Menu $isOpen={isMenuOpen}>
                    <MenuItem onClick={() => {
                        setMenuOpen(false)
                        navigate("/")
                    }}>{t('Menu1')}</MenuItem>
                    <MenuItem onClick={() => {
                        setMenuOpen(false)
                        navigate("/pricing")
                    }}>{t('Menu2')}</MenuItem>
                    {/*<MenuItem>{t('Menu3')}</MenuItem>*/}
                    {/*<MenuItem>{t('Menu4')}</MenuItem>*/}
                    {/*<MenuItem>{t('Menu5')}</MenuItem>*/}
                    <MenuItem onClick={() => {
                        setMenuOpen(false)
                        navigate("/contact")
                    }}>{t('Menu6')}</MenuItem>
                    <MenuItem onClick={() => {
                        setMenuOpen(false)
                        navigate("/gallery")
                    }}>{t('Menu7')}</MenuItem>
                </Menu>
                <BlurRight/>

                {/*<CardBlock style={{paddingRight: '100px'}}>*/}
                {/*    <BlurRight/>*/}
                {/*    <Icon $image={BagIcon}/>*/}
                {/*    <CardText onClick={() => setShowBlock(true)}>Card</CardText>*/}
                {/*    /!*<Icon $image={PhoneIcon}/>*!/*/}
                {/*</CardBlock>*/}
            </MenuBlock>
            <Outlet/>
        </Container>
    );
};

export default MainPage;
