import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AsyncThunkConfig, AuthState, IError, INewUser,} from "../types";
import authService from "./authService";

export const logoutFunc = createAsyncThunk(
    "auth/logout",
    async (_, thunkAPI) => {
        try {
            await authService.logOut();
            window.location.href = "/";
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const setUpRecaptchaFunc = createAsyncThunk(
    "setUpRecaptchaFunc",
    async (_, thunkAPI) => {
        try {
            const response = await authService.setUpRecaptcha();
            await window.recaptchaVerifier.render();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error);

        }
    }
);


export const onSignInSubmitFunc = createAsyncThunk<
    void,
    string,
    AsyncThunkConfig
>(
    "onSignInSubmit",
    async (phone: string, { rejectWithValue }) => {
        try {
            const response = await authService.onSignInSubmit(phone);
            if (response && 'code' in response) {
                return rejectWithValue(response as IError);
            }
        } catch (error: any) {
            return rejectWithValue({
                code: error.code || "unknown_error",
                message: error.message
            });
        }
    }
);
export const onVerifyCodeSubmitFunc = createAsyncThunk(
    "onVerifyCodeSubmit",
    async (verificationCode: string, thunkAPI) => {
        try {
            const result = await authService.onVerifyCodeSubmit(verificationCode);
            return result;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({
                code: error.code || "verification_failed",
                message: error.message || 'Verification failed.'
            });
        }
    }
);

const initialState: AuthState = {
    loading: false,
    user: null as INewUser | null,
    error: null,
    sending: false,
    capcha: false
};
const userSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setSending: (state, action) => {
            state.sending = action.payload;
        },
        setCapcha: (state, action) => {
            state.capcha = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutFunc.fulfilled, (state, action) => {
                Object.assign(state, initialState);
            })
            .addCase(setUpRecaptchaFunc.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(setUpRecaptchaFunc.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(setUpRecaptchaFunc.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as IError;
            })
            .addCase(onSignInSubmitFunc.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(onSignInSubmitFunc.fulfilled, (state, action) => {
                state.loading = false;
                state.sending = true;
            })
            .addCase(onSignInSubmitFunc.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as IError;
            })
            .addCase(onVerifyCodeSubmitFunc.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(onVerifyCodeSubmitFunc.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(onVerifyCodeSubmitFunc.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as IError;
            })
    }
});

export const {setSending,setCapcha} = userSlice.actions;
export default userSlice.reducer;
