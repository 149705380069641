import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCGxUmVQK6QzEz3MWQ3IBpTnNMZezJHhz0",
  authDomain: "davidzaguri-a2474.firebaseapp.com",
  databaseURL: "https://davidzaguri-a2474-default-rtdb.firebaseio.com",
  projectId: "davidzaguri-a2474",
  storageBucket: "davidzaguri-a2474.appspot.com",
  messagingSenderId: "575519046787",
  appId: "1:575519046787:web:658379a64da98d299dafb6",
  measurementId: "G-S927N9ZMXV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the authentication and Firestore instances
export const auth = getAuth(app);
export const db = getFirestore(app);
