import {Route, Routes} from "react-router-dom";
import MainPage from "./components/MainPage/MainPage";
import {routes} from "./routes";
import Gallery from "./components/Gallery/Gallery";
import HomeComponent from "./components/HomeComponent/HomeComponent";
import NfcPage from "./components/nfcpage/NfcPage";
import PricingPage from "./components/PricingPage/PricingPage";
import ContactComponent from "./components/ContactComponent/ContactComponent";


export const AppRouter = () => {
    return (
        <Routes>
            <Route path={routes.HOME} element={<MainPage/>}>
                <Route index element={<HomeComponent/>}/>
            </Route>
            <Route path={routes.NFC} element={<NfcPage/>}/>
            <Route path="/" element={<MainPage/>}>
                <Route index element={<HomeComponent/>}/>
                <Route path={routes.GALLERY} element={<Gallery/>}/>
                <Route path={routes.PRICING} element={<PricingPage/>}/>
                <Route path={routes.CONTACT} element={<ContactComponent/>}/>
            </Route>
        </Routes>
    );
};
