import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    z-index: 1;
`;
export const CenterElements = styled.div`
    column-count: 2; // Количество колонок
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    gap: 10px;
`;
export const Scroll = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 200px;
`;
export const ScrollBookings = styled.div`
    position: static;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;
