import {createAction} from "@reduxjs/toolkit";
import {IError, IUpdateUser,} from "../types";
import {RecaptchaVerifier, signInWithPhoneNumber, signOut, updateProfile} from "firebase/auth";
import {auth} from "../../firebaseConfig";


const authService = {


    async update(payload: IUpdateUser) {
        try {
            if (auth.currentUser) {
                await updateProfile(auth.currentUser, {
                    displayName: payload?.displayName,
                    photoURL: payload?.photoURL,
                });
            }
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },
    async logOut() {
        try {
            await signOut(auth);
            console.log("Logout successful");
        } catch (error) {
            console.log(error);
        }
    },

    async setUpRecaptcha() {
        try {
            const recaptchaContainer = document.getElementById('recaptcha-container');
            if (!recaptchaContainer) {
                throw new Error('reCAPTCHA container not found.');
            }

            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'normal',
                'callback': (response:any) => {
                    // Handle reCAPTCHA success
                    // this.onSignInSubmit(phone);
                    console.log('reCAPTCHA verified.!! Please submit the form.');

                },
                'expired-callback': () => {
                    // Handle expired reCAPTCHA
                    console.log('reCAPTCHA expired. Please try again.');
                }
            });

            // Render the reCAPTCHA widget
            // await window.recaptchaVerifier.render();

        } catch (error: any) {
            console.error('Error setting up reCAPTCHA:', error);
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },

    async onSignInSubmit(phone?: string) {
        try {
            const appVerifier = window.recaptchaVerifier;
            if (!phone) {
                throw new Error('Phone number is required');
            }
            window.confirmationResult = await signInWithPhoneNumber(auth, phone, appVerifier);
            console.log("OTP sent. Enter the code below.");
        } catch (error: any) {
            console.error('Error during sign-in:', error);
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },

    async onVerifyCodeSubmit(verificationCode?: string) {
        try {
            if (!window.confirmationResult) {
                throw new Error('Confirmation result not found');
            }
            const result = await window.confirmationResult.confirm(verificationCode || '');
            const user = result.user;
            console.log("User signed in successfully:", user);
            return user;
        } catch (error: any) {
            console.error('Error verifying code:', error);
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },
};

export const logout = createAction("auth/logout");

export default authService;
