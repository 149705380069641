import React, {useState} from 'react';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import {Button, Buttons, Info, InfoBlock, SmallText, SocialButtons, SocialIcon, Title} from "../../style/GlobalStyle";
import FacebookIcon from "../../assets/images/Facebook.svg";
import InstaIcon from "../../assets/images/InstaIcon.svg";
import {Overlay} from "../MainPage/styles";
import CardContent from "../MainPage/CardContent";
import {INewUser} from "../../store/types";
import {useAppSelector} from "../../store/hooks";
import {useTranslation} from "react-i18next";

const HomeComponent: React.FC = () => {
    // const onInit = () => {
    //     console.log('lightGallery has been initialized');
    // };
    const [showBlock, setShowBlock] = useState(false);
    const user = useAppSelector(state => state.app.user)
    const {t} = useTranslation();

    return (
        <>
            <InfoBlock>
                <Title>
                    {t('InfoTitle')}
                </Title>
                <Info>
                    {t('InfoText')}
                </Info>
                <SmallText>
                    {t('InfoSmallText')}
                </SmallText>
                <Buttons>
                    <Button onClick={() => setShowBlock(true)}>{t('ButtonBook')}</Button>
                    {/*<Button>NOOk</Button>*/}
                </Buttons>
                <SocialButtons>
                    <SocialIcon $image={FacebookIcon}/>
                    <SocialIcon $image={InstaIcon}/>
                </SocialButtons>
            </InfoBlock>
            {showBlock && (
                <Overlay>
                    <CardContent user={user as INewUser} setShowBlock={setShowBlock}/>
                </Overlay>
            )}
        </>
    );
};

export default HomeComponent;
