import React from "react";
import styled from "styled-components";
import {Container} from "./styles";
import {Scroll} from "../Gallery/styles";

const ServiceList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    z-index: 5;
padding: 30px;
`;

const ServiceCategory = styled.div`
    flex: 1;
    max-width: 50%;
    min-width: 300px;
    border-radius: 8px;
    padding: 20px;
    background: rgba(151, 118, 86, 0.3);
    border: 1px solid rgb(191, 150, 111);`;

const CategoryTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: white;
`;

const ServiceItem = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: white;
`;

const ServiceName = styled.span`
  flex: 1;
`;

const ServicePrice = styled.span`
    width: auto;
    height: auto;
    margin-left: 10px;
    font-weight: bold;
    color: white;
`;

interface Service {
    name: string;
    price: string;
}



const PricingPage: React.FC = () => {
    const maleServices: Service[] = [
        { name: 'תספורת גבר', price: '50' },
        { name: 'תספורת ילד', price: '50' },
        { name: 'צבע שיער גבר', price: '20' },
        { name: 'שעווה גבות', price: '20' },
        { name: 'שעווה לחיים', price: '10' },
        { name: 'שעווה אוזניים', price: '10' },
        { name: 'כל הפנים', price: '30' }
    ];

    const femaleServices: Service[] = [
        { name: 'פן', price: '50-120' },
        { name: 'תספורת אישה', price: '70-150' },
        { name: 'בייביליס', price: '120-200' },
        { name: 'החלקה', price: '500-800' },
        { name: 'גוונים', price: '500-1000' },
        { name: 'צבע', price: '150-350' }
    ];

    return (
        <Container>
            <Scroll>
            <ServiceList>
                <ServiceCategory>
                    <CategoryTitle>גברים</CategoryTitle>
                    {maleServices.map((service, index) => (
                        <ServiceItem key={index}>
                            <ServiceName>{service.name}</ServiceName>
                            <ServicePrice>{service.price}</ServicePrice>
                        </ServiceItem>
                    ))}
                </ServiceCategory>
                <ServiceCategory>
                    <CategoryTitle>נשים</CategoryTitle>
                    {femaleServices.map((service, index) => (
                        <ServiceItem key={index}>
                            <ServiceName>{service.name}</ServiceName>
                            <ServicePrice>{service.price}</ServicePrice>
                        </ServiceItem>
                    ))}
                </ServiceCategory>
            </ServiceList>
            </Scroll>
        </Container>
    );
};

export default PricingPage;
