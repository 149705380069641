import React from "react";
import {IconWrapper} from "../style/GlobalStyle";

interface ICloseIconProps {
    onClick?: () => void;
}
const CircleIcon: React.FC<ICloseIconProps> = ({ onClick }) => {
    return (
        <IconWrapper onClick={onClick}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    >
            <path id="icon" d="M8.25 15.75C4.10791 15.75 0.75 12.3921 0.75 8.25C0.75 4.10791 4.10791 0.75 8.25 0.75C12.3921 0.75 15.75 4.10791 15.75 8.25C15.75 12.3921 12.3921 15.75 8.25 15.75ZM5.75 5.75L8.25 8.25L10.75 10.75M10.75 5.75L8.25 8.25M8.25 8.25L5.75 10.75" stroke="#363A3D" strokeOpacity="1.000000" strokeWidth="1.500000"/>
        </svg>
        </IconWrapper>
);
};

export default CircleIcon