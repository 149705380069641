import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Buttons} from "./style";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng:string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Buttons>
            <Button onClick={() => changeLanguage('en')}>English</Button>
            <Button onClick={() => changeLanguage('ru')}>Русский</Button>
            <Button onClick={() => changeLanguage('he')}>עברית</Button>
        </Buttons>
    );
};

export default LanguageSwitcher;
